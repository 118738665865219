.navigation {
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 2;
  filter: invert(30%);
  transition: all 0.4s;
  display: flex;
  @include mobile{
    filter: invert(0%);
    background: rgba(0,0,0,0.6);
    height: 60px;
  }
  &__burger {
    &--lines {
      // @include small-desk {
        display: flex;
        justify-content: space-between;
        height: 10%;
        width: 100%;
        background-color: $white;
        border-radius: 2px;
        margin-bottom: 30%;
        transition: all $medium $ease-in-out;
        @media all and (orientation:landscape) {
          height: 5%;
          width: 50%;
          margin-bottom: 15%;
        }
        &.open {
          &:nth-child(1){
            transform-origin: center;
            transform: translate(0px, 12px) rotate(45deg);
            @media all and (orientation:landscape) {
              transform: translate(10px, 9px) rotate(45deg);
            }
          }
          &:nth-child(2) { opacity: 0; }
          &:nth-child(3) {
            transform-origin: center;
            transform: translate(0px, -13px) rotate(-45deg);
            @media all and (orientation:landscape) {
              transform: translate(10px, -12px) rotate(-45deg);
            }
          }
        }
      // }
    }
    display: none;
    @include small-desk {
      display: block;
      height: 50px;
      width: 50px;
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      z-index: 3;
      margin: 30px 30px 0 0;
    }
    @include med-tablet {
      height: 50px;
      width: 50px;
    }
    @include mobile {
      height: 30px;
      width: 30px;
      margin: 15px 30px 0 0;
    }
  }
  &__container {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }
  &--logo {
    z-index: 3;
    height: 60px;
    width: 270px;
    margin-left: 1.5rem;
    filter: brightness(4);
    @include bg-contain();
    background-image: url(/assets/logos/HatchQuarter_Logo_horizontal.png);
    margin-top: 15px;
    @include small-desk {
      position: fixed;
      z-index: 1000;
      top: 0%;
      left: 0%;
      height: 60px;
      width: 33%;
      margin-left: 24px;
    }
    @include mobile {
      margin-top: 0;
      height: 60px;
      width: 40%;
      margin-top: 0;
    }
    @media all and (orientation:landscape) {
      top: 0%;
      left: 0%;
    }
  }
  &__list {
    list-style: none;
    // margin-right: $base*2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    @include small-desk {
      z-index: 2;
      height: 100vh;
      width: 100vw;
      outline: 1px solid white;
      width: 100vw;
      border-bottom: none;
      position: fixed;
      top: 0%;
      left: 0%;
      margin: 0;
      transform: translateX(100vw);
      transition: transform $medium $ease-in-out;
      background-color: $dark-grey;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-item {
      color: $white;
      padding: 10px;
      display: flex;
      padding: 0;

      margin: $base;
      transition: all $short $ease-in-out;
      &:hover {
        color: $yellow;
      }
      &:last-child {
        color: $white;
        background: $yellow;
        color: $black;
        font-weight: 500;
        padding: 15px 25px;
        display: none;
      }
      &:last-child:hover {
        background: #355c7d !important;
        cursor: pointer !important;
      }
      @include small-desk {
        transition: all $medium $ease-in-out;
        display: block !important;
        margin: 0 0 1rem 0;
        opacity: 0;
        transform: translateY(50px);
      }
      @include mobile{
        margin-top: 5px !important;
      }
      @media all and (orientation:landscape) {
        margin-bottom: 1.75rem;
      }
    }
  }
}
.current--page {
  color: $black;
  border-bottom: 1px solid black;
  filter: invert(100%);
  @include tablet {
    color: $yellow;
    border-bottom: 1px solid $yellow;
    filter: none;
  }
}
.active-nav {
  transform: translateX(0px);
}

.navigation__list-item {
  margin-top: 24px;
  a::after {
      content: '';
      display: block;
      margin: auto;
      height: 2px;
      width: 0px;
      background: transparent;
      transition: width .33s ease, background-color .33s ease;
  }
  a[href="/apply-to-hq"]::after {
    height: 0px !important;
  }
}
.navigation__list-item a:hover::after {
    width: 100%;
    background: #FBC926;
}

.sticky {
  background: rgba(0,0,0,0.6);
  filter: invert(0%);
}

/* .navigation__list-item:last-child {

} */
.navigation__list-item:last-child:hover {
    background: #FBC926;
    color: #fff;
}
