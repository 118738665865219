
.meetup {
  @include mobile {
    max-width: 85vw;
    margin: auto;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/Connect/001.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__left-top {
    height: 40vh;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include mobile {
      padding-left: 0;
    }

  }
  &__left-bottom {
    padding-left: 2rem;

    @include mobile {
      padding-left: 0;
    }

    p {
      @extend .text-small;
      @extend .text-lineSpace;
      width: 70%;
      @include mobile {
        width: 100%;
      }
    }
     ul {
       color: $white;
       width: 70%;
       @extend .text-small;
       list-style-type: disc;
       padding-left: 2rem;
       margin-top: 2rem;
       @include mobile {
         width: 100%;
       }
     }
     li {
       list-style-type: disc;
         margin-bottom: 0.5rem;
     }
  }
  &__right-top {
    height: 40vh;
    width: 100%;
    position: relative;
    @include mobile {
      height: auto;
    }

  }
  &__right {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
    &__right-bottom {

    }
    &__right-bottom-inner {
      border: 2px solid $yellow;
      width: 80%;
      margin-top: 1rem;
      @include mobile {
        width: 100%;
      }
    }

  &__link {
    background-color: rgba($light-grey, 1);
    position: relative;
    padding: 1rem 0.5rem 1rem 4rem;
    @include mobile {
      padding-left: 4rem;
    }
  }
  &__link-image {
    width: 2rem;
    height: 2rem;
    position: absolute;
    background-image: url(../assets/images/hatchquarter-start-program-link.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 1.3rem;
    top: 1rem;
    margin-right: 0.5rem;
  }

  &__footer-links {
    border-top: 2px solid $yellow;
    margin-top: 2rem;
  }
  &__footer-links-inner {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    margin: auto;
    padding-top: 2rem;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      flex-flow: column;
    }
    .button {
      height: 40px;
    }
  }
  &__footer-link {
    color: $white;
    text-decoration: underline;
    @include mobile {
      margin-bottom: 1rem;
    }
  }
  &__left-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 2rem;
    a {
      width: 50%;
      margin-bottom: 1rem;
      color: $yellow;
      text-decoration: none;
      transition: color 0.5s ease;
      position: relative;
      z-index: 100;
      @extend .text-small;

      &:hover {
        color: $white;
      }
      @include mobile {
        width: 100%;
      }

    }
  }
}




.connect-playbook {
  margin-top: 2rem;
  @include mobile {
    max-width: 85vw;
    margin: auto;
    margin-top: 2rem;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/Connect/002.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }
  }
  &__right {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__right-top {
    height: 40vh;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include mobile {
      padding-left: 0;
    }
  }
  &__right-bottom {
    padding-left: 2rem;

    @include mobile {
      padding-left: 0;
    }

    p {
      @extend .text-small;
      @extend .text-lineSpace;
      width: 70%;
      @include mobile {
        width: 100%;
      }
    }
     ul {
       color: $white;
       width: 70%;
       @extend .text-small;
       list-style-type: disc;
       padding-left: 2rem;
       margin-top: 2rem;
       @include mobile {
         width: 100%;
       }
     }
     li {
       list-style-type: disc;
         margin-bottom: 0.5rem;
     }
  }
  &__right-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 2rem;
    a {
      width: 50%;
      margin-bottom: 1rem;
      color: $yellow;
      text-decoration: none;
      position: relative;
      z-index: 100;
      @extend .text-small;

      &:hover {
        color: $white;
      }
      @include mobile {
        width: 100%;
      }

    }
  }

}



.crowdfunding {
  margin-top: 2rem;
  @include mobile {
    max-width: 85vw;
    margin: auto;
    margin-top: 2rem;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/Connect/003.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__left-top {
    height: 40vh;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include mobile {
      padding-left: 0;
    }

  }
  &__left-bottom {
    padding-left: 2rem;

    @include mobile {
      padding-left: 0;
    }

    p {
      @extend .text-small;
      @extend .text-lineSpace;
      width: 70%;
      @include mobile {
        width: 100%;
      }
    }
     ul {
       color: $white;
       width: 70%;
       @extend .text-small;
       list-style-type: disc;
       padding-left: 2rem;
       margin-top: 2rem;
       @include mobile {
         width: 100%;
       }
     }
     li {
       list-style-type: disc;
         margin-bottom: 0.5rem;
     }
  }

  &__right {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  &__left-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: 2rem;
    color: $white;

    &:hover {
      color: $yellow;
    }

    & a:link {
      width: 50%;
      margin-bottom: 1rem;
      color: $yellow;
      text-decoration: none;
      position: relative;
      z-index: 100;
      @extend .text-small;
      &:hover {
        color: $white;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
  &__top-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    margin-top: 2rem;
    a {
      width: 40%;
      margin-bottom: 1rem;
      color: $white;
      //padding-left: 1rem;
      @extend .text-small;
      @include mobile {
        width: 100%;
      }

    }
  }
}


.social {
  margin-top: 2rem;
  @include mobile {
    max-width: 85vw;
    margin: auto;
    margin-top: 2rem;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/Connect/004.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }
  }
  &__right {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__right-top {
    height: 40vh;
    width: 100%;
    margin-left: -2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;
    @include mobile {
      padding-left: 0;
    }
  }
}


.meetup-outer {
  @include mobile {
    padding-top: 0;
  }
}
