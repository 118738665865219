.videowrapper {
  display: flex;
  flex-flow: row wrap;

  &__video {
    width: 35%;

    @include mobile {
      width: 100%;
    }

    div {
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;
      background-position: center;
      margin: auto;
      @include mobile {
        height: 150px;
        width: 150px;
        margin-bottom: 2rem;
      }
    }
  }
  &__content {
    width: 65%;

    @include mobile {
      width: 100%;
    }
  }
}
