.footer {
  position: relative;
  width: 100vw;
  height: auto;
  //min-height: 60vh;
  width: 100%;
  @include tablet { /*min-height: 45vh; */}
  @include mobile { }
  @include small-mobile { /*min-height: 85vh; */}
  &__menu {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 2rem;
    @include mobile {
      margin-top: 2.5rem;
    }
  }
  &__col {
    display: flex;
    flex-grow: 1;
    &:first-child {
      content: '';
      flex-grow: 2;
      @include tablet { flex-grow: 1; }
      @include mobile {  display: none; }
    }
  }
  &__container {
    width: 85vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__bottom {
    color: $black;
    background: $yellow;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 50px;
    //border-top: 1px solid $white;
    position: relative;
    bottom: 0%;
    left: 50%;
    padding-left: 10%;
    padding-right: 10%;
    //padding: $base;
    transform: translateX(-50%);
    //margin-bottom: $base;;
    @include mobile{
      font-size: 0.75em;
      text-align: center;
    }
    p {
      margin: 0;
      font-size: 14px;
      @extend .text-thicker;
    }

    a:hover { color: $white; }
    @include mobile {
      display: block;
      margin: 0;
      height: 16%;
      p {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
        margin-top: 12px;
      }
      * { text-align: left; }
    }

  }
  &--list {
    margin: 0 auto;
    @include mobile {
      margin-bottom: $base;
    }
    transition: all $medium $ease-in-out;
    li{
      border-left: 1px solid $white;
      padding-left: 10px;
      @extend .text-small;

    }
    li:hover { color: $yellow;
border-left: 1px solid $yellow;
    }
  }
  &--label {
    width: 100%;
    text-align: center;
    @include mobile {
      width: 90%;
      margin: 0 auto;
    }
  }
  &--form {
    width: 90%;
    margin: $base auto;

    @include tablet {
      width: 75%;
      text-align: center;
    }
    @include mobile {
      width: 90%;
    }
    .name-field-container {
      input {
        border-left: none;
        @include mobile {
          border-left: 1px solid $white;
        }
      }
      @include mobile {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
    .email-field-container {
      @include mobile {
        margin-bottom: 1rem;
        width: 100%;
      }
    }
    .submit-button-container {
      @include mobile {
        width: 100%;
      }
    }
  }
  &--logo {
    height: 100%;
    width: 100%;
    @include bg-contain();
    background-repeat: no-repeat;
    background-image: url(/assets/logos/footer-logo.png);
  }

  .email-field-container {
    border-top: 1px solid White;
    input {
      border-top: none;

    }
  }
.social-link
{
  background: $white;
  padding: 3px 2px 2px;
  color: $black;
}

.social-link:hover
{
  color:$yellow;
}
}
