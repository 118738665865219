.cta {
  position:fixed;
  z-index: 999;
  display: inline-block;
  bottom: 2rem;
  right: 2rem;
  //outline: 1px solid red;

  transition: all 0.6s cubic-bezier(0.585, 0.2, 0.07, 1);
  @include mobile {
    display: none;
  }
  &:before, &:after{
    content: "";
    display: block;
    transition: transform 2400ms $ease-in-out;
  }

  &:before{
    width: 80px;
    height: 80px;
    position: relative;
    background: #FBC926;
    opacity: 0.8;
    @include shadow-high;
    @include mobile {
      width: 50px;
      height: 125px;
      left: -100%;
    }
  }

  &:after {
    content: "Apply";
    font-size: 1.25rem;
    width: 10rem;
    @include centerme;
    color: $black;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    @include mobile {
      font-size: 1.25rem;
      left: 65vw;
      top: 90vh;
    }
  }

  &:hover:before {
    transform: scale(1.25);
    transition: transform 6400ms $ease-out;
  }

  &:hover:after {
    transform: translateY(0%) translateX(-50%);
    transition: transform 6400ms $ease-out;
  }

  &--hide{
    opacity: 0;
    pointer-events: none;
  }

  &--offscreen{
    &:after{
      transform: translateX(150%);
    }
    &:before{
      transform: translateX(150%);
    }
  }

}
.apply-page {
  .header__footer {
    display: none;
  }
}
.application--formOuter {
  border-top: 1px solid $light-grey;
}

.application--form {
  .select-wrapper:after {
    top: 9px;
  }
  .select-label {
    opacity: 1 !important;
  }

}

.radio-wrapper {
  display: none;
  margin-top: 1rem;
}
.radio-wrapper.active {
  display: block;
}
