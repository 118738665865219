.program{
  &--details {
    width: 100%;
    height: auto;
    z-index: 2;
    &__inner {
      width: 80%;
      margin: 0;
      padding: $base;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &:before{
        content: '';
        @include abfab;
        height: 200%;
        background: $light-grey;
        z-index: -1;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        opacity: 0.3;
      }
      //box-shadow: $shadow;
      @include tablet {
        width: 100%;
        margin: 2rem 0;
        &:before{
          transform: rotate(0deg);
        }
      }
      @include small-mobile {
        font-size: 15px;
      }
    }
    @include tablet {
      width: 80%;
      margin: 0 auto;
    }
    &-list {
      list-style: disc;
      padding: $base;
      padding-left: $base * 2;
      margin-bottom: $base;
      @include tablet {
        width: 45%;
        float: left;
      }
      @include mobile{
        width: 100%;
        float: none;
      }
      &-item {
        list-style: disc;
        font-size: 1.25em;
        line-height: 1.75;
      }
    }
    &-button {
      cursor: pointer;
      margin-bottom: $base;
      @include tablet {
        width: 50%;
        float: right;
        transform: translateY(100%);
      }
      @include mobile{
        width: 100%;
        float: none;
        transform: translateY(0%);
      }
      button { width: 100%; }
    }
  }
  &--summary {
    width: 100%;
    @include tablet {
      width: 90%;
      margin: 0 auto;
    }
  }
  &--list {
    list-style: circle;
    width: 100%;
    color: $blue;
    @include tablet {
      width: 90%;
      margin: 0 auto;
    }
    &-item {
      margin: 0;
      height: auto;
      position: relative;
      padding-left: 38px;
      padding-bottom: $base;
      margin-bottom: $base;
      border-bottom: 1px solid $light-grey;

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        border-top: 20px solid $yellow;
        border-left: 20px solid transparent;
        height: 0;
        width: 0;
        transition: transform $medium $ease-out;
        @include tablet {
          left: -2.57%;
        }
      }
      @include mobile {
        padding-left: $base*2;
      }
      &--heading {
        line-height: 1;
        font-size: 1.5em;
        font-weight: 300;
        text-transform: capitalize;
        @include mobile {
          font-size: 1em;
        };
      }
      &--content {
        margin-top: $base/2;
      }
      &.accordian--open:before{
        transform: rotate(90deg);
      }
    }
  }
  &--results {
    @include span(4);
    padding: $base;
    border-top: 1px solid $white;
    border-right: 1px solid rgba($white,0.25);
    border-bottom: none;
    border-left: none;
    &:last-child{ border-right: none; }
    @include mobile {
      @include span(80%);
      transform: translateX(10%);
      border-right: none;
      &:last-child{ margin-bottom: $base*2; }
    }
  }

}

