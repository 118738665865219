$translate: translateY(-35%);
$mobileTranslate: translateY(-5%);

// .contact {
//   padding: 3rem 0;
//   transform: $translate;
//   //box-shadow: $mild-shadow;
//   border-radius: 5px;
//   height: 100%;
//   @include mobile {
//     transform: $mobileTranslate;
//     width: 90%;
//     margin: 0 auto;
//   }
//   &__inner {
//     margin: $base auto;
//     width: 90%;
//     display: flex;
//     justify-content: space-between;
//     height: auto;
//     @include tablet {
//       flex-wrap: wrap;
//     }
//     a {
//       margin-left: 2.5%;
//     }
//   }
//   &--list {
//     width: 70%;
//     margin: 0 auto;
//     margin-bottom: 1rem;
//     font-size: $heading-size*1.5;
//     @include mobile {
//       width: 90%;
//       font-size: $heading-size;
//       margin-bottom: 2rem;
//     }
//     &-item {
//       margin-bottom: $base;
//     }
//   }
//   &--card {
//     height: 200px;
//     background-color: rgba(0,0,0,0.33) !important;
//     padding: $base;
//     //box-shadow: $mild-shadow;
//     @include span(20%);
//     &:first-child {
//       margin-left: 2.5%;
//       @include tablet {
//         margin-left: 0;
//       }
//     }
//     @include tablet {
//       @include span(45%);
//       margin-right: 10%;
//       margin-bottom: 2rem;
//       &:nth-child(2n) {
//         margin-right: 0;
//       }
//     }
//     @include mobile {
//       height: 100%;
//     }
//     &-heading {
//       padding-bottom: $base;
//       margin-bottom: $base;
//       border-bottom: 1px solid grey;
//     }
//   }
//   &--form {
//     padding: 2rem;
//     transform: $translate;
//     @include mobile {
//       padding: 0;
//       transform: $mobileTranslate;
//       width: 100%;
//     }
//   }
// }

.contact {
  &__header {
    position: relative;
    border-bottom: 2px solid $light-grey;
  }
  &__header-inner {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &__heading {
    font-size: 48px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 2rem;
    font-family: 'piersans';
  }
  &__header-content {
    padding-bottom: 2px solid $light-grey;
  }
  &--form {
    transform: translateY(-8rem);
    background-color: $white;
    padding: 4rem 6rem;
    @include tablet
    {
      padding: 2rem 2rem;
    }

    @include mobile
    {
      padding: 2rem 1rem;
    }
    h2 {
      margin-bottom: 2rem;
    }
  }
}

#map-canvas {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
}
