.consultancy {
  &__outer {
    @include span(45%);
    margin: 2.5%;
  }
  &__inner {
    transform: translate(30px, 50px);
  }
  &__list{
    &-item {
      width: 100%;
      height: 120px;
      border-top: 1px solid rgba(white,0.25);
      &:first-child{
        border-top: 2px solid white;
      }
      transition: all $medium $ease-in-out;
      &-number {
        text-align: center;
        color: $yellow;
        width: 12%;
        height: 90%;
        line-height: 120px;
        font-size: 2em;
        float: left;
        border-right: 1px solid rgba(white,0.25);
      }
      &-content {
        width: 88%;
        padding: 0 4rem;
        float: right;
        margin-top: 1.5em;
        white-space: normal;

        @include mobile {
          width: 88%;
          padding: 0 1.5em;
        }
      }
    }
  }
}
