.starting-position {
  animation: start 2s;
}

@keyframes start {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
