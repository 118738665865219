.about-block {
  margin-top: 2rem;
  @include mobile {
    max-width: 85vw;
    margin: auto;
    //margin-top: 2rem;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url(../assets/images/about/Thumbnails-about01.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: right;
      }
    }
  }

  &__inner2 {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url(../assets/images/about/Thumbnails.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: right;
      }
    }
  }

  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__left-top {
    height: 40vh;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include mobile {
            padding-left: 1rem;
      position:relative;
      top:10vh;
      height:17vh;
      margin-bottom:23vh;
      background:rgba(255,255,255,0.5);
      width:100%;
    }

  }
  &__left-bottom {
    padding-left: 2rem;

    @include mobile {
      padding-left: 0;
    }

    p {
      @extend .text-small;
      @extend .text-lineSpace;
      width: 70%;
      @include mobile {
        width: 100%;
      }
    }
     ul {
       color: $white;
       width: 70%;
       @extend .text-small;
       list-style-type: disc;
       padding-left: 2rem;
       margin-top: 2rem;
       @include mobile {
         width: 100%;
       }
     }
     li {
       list-style-type: disc;
         margin-bottom: 0.5rem;
     }
  }

  &__right {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  &__top-image {
    height: 35vh;
    width: 100%;
    margin-top: 2.5vh;
  }

  &__left-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 2rem;
    a {
      width: 50%;
      margin-bottom: 1rem;
      color: $white;
      text-decoration: underline;
      @extend .text-small;
      @include mobile {
        width: 100%;
      }

    }
  }
  &__top-link {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
    margin-top: 2rem;
    a {
      width: 40%;
      margin-bottom: 1rem;
      color: $white;
      //padding-left: 1rem;
      @extend .text-small;
      @include mobile {
        width: 100%;
      }

    }
  }
}

.about-block-outer {
  @include mobile {
    padding-top: 0;
  }
}

.view-playbook-wrapper {
  text-align: center;
  padding: 0;
  overflow: visible;
  div {
    width: auto;
    transform: translateY(-50%);
  }
}

.about-startup {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  justify-content: space-between;
  &__col {
    width: 24%;
    margin-bottom: 1rem;
    @include tablet {
      width: 48%;
    }
    @include mobile {
      width: 100%;
    }
  }

  &__image {
    height: 9rem;
    width: 9rem;
    margin: auto;
    margin-bottom: 1.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include mobile{
        height: 15rem;
        width: 15rem;
    }
  }
  &__content {
    padding: 0 0.5rem 0.5rem;
  }
  p {
    height: 6vh;
    padding-bottom: 1rem;
  }
  h3 {
    padding-bottom: 1rem;
  }
}
