/*--- All UI components to be styled in here. Aim to make them as reusable as possible. ----*/
.client {
  position: relative;
  @include mobile {
    width: 90%;
    margin: 0 auto;
  }
  &--item {
    width: 20%;
    height: 5rem;
    float: left;
    @include bg-contain();
    &:nth-child(1) { background-image: url(/assets/logos/Mo-works-creative-agency.png); }
    &:nth-child(2) { background-image: url(/assets/logos/blitzm-systems.png); }
    &:nth-child(3) { background-image: url(/assets/logos/docklands-chamber-of-commerce.png); }
    &:nth-child(4) { background-image: url(/assets/logos/a-capital-hatch-quarter-dubai.png); }
    &:nth-child(5) { background-image: url(/assets/logos/AWS-production.png); }
    margin-bottom: $base;

    @include tablet {
      width: 33%;
    }
    @include tablet {
      width: 50%;
    }
  }
}
.testimonial {
  height: auto;
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--text {
    width: 100%;
    text-align: center;
    color: $dark-grey;
    @include tablet {
      width: 100%;
      margin: $base auto;
    }
  }
  @include tablet {
    min-height: 45vh;

  }
}

//Commnity cards on about page
.community {
  height: 40vh;
  &--image {
    width: 90%;
    height: 60%;
  }
}
.image-strip {
  @include bg-image();
  background-attachment: fixed;
  background-image: url(/assets/images/workspace.jpg);
  @include mobile {
    background-attachment: scroll;
  }
}


// #map-canvas {
//   height: 75vh;
//   margin:0;
//   padding:0;
//   width:100%;
// }
