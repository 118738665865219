//text
.text-black{ color: $black;}
.text-light-grey{ color: $light-grey}
.text-medium-grey{ color: $medium-grey}
.text-dark-grey{ color: $dark-grey}
.text-blue { color: $blue; }
.text-yellow { color: $yellow; }
.text-white { color: $white; }

.text-upper{
	text-transform: uppercase;
	letter-spacing: 0.02em;
}
.text-left{ text-align: left; }
.text-center{ text-align: center; }
.text-right{ text-align: right; }
.mobile-text-left {
	@include mobile{
		text-align: left;
	}
}

.text-tiny{
	font-size: 14px;
	margin-bottom: 0;
	@include mobile{ font-size: calc(4px + 1vh); }
}

.text-small{
	font-size: 16px;
	@include mobile{ font-size: calc(4px + 1vh);
}
}

.text-space
{
	margin-top: 1rem;
	line-height: 1.5rem;
	margin-bottom: 2rem;
	@include mobile
	{
		margin-bottom: 1rem;
		line-height: 1.2rem;
	}
}
.text-medium{
	font-size: 18px;
	margin-bottom: 0;
	letter-spacing: 1.2px;
	line-height: 1.2;
	white-space: pre-wrap;
	@include mobile{
		font-size: calc(4px + 1.5vh);
	}
}

.text-large{
	font-size: 20px;
	font-weight: 300;
	line-height: 1.2;
	@include mobile{
		font-size: calc(4px + 2vh);
	}
}

.text-medium, .text-large, .text-larger{
	white-space: pre-wrap;
	@include tablet{
		white-space: normal;
	}
}

.text-larger{
	font-size: 27px;
	line-height: 1.5;
	@include mobile{ font-size: calc(4px + 2vh); }
}
.text-huge{
	font-size: 36px;
	font-weight: 500;
	line-height: 1.2;
	@include mobile{ font-size: calc(4px + 2vh); }
}
.section-heading{
	font-size: 26px;
	//font-weight: 300;
	line-height: 1.2;
	font-family: 'piersans';
	@include mobile{ font-size: calc(12px + 2vh);  }
}
.text-massive{
	font-size: 48px;
	font-weight: 500;
	line-height: 1.1;
	letter-spacing: 0;
	@include mobile{ font-size: calc(4px + 2.6vh); }
}

.drop-cap{
	&:first-letter, *:first-child:first-letter {
		float: left;
		font-size: 5.5em;
		line-height: 0.6;
		padding-top: 0.1em;
		padding-right: 0.1em;
		padding-left: 0em;
		font-weight: 100;
	}
}

.text-thin{ font-weight: 100}
.text-thick{ font-weight: 500}
.text-thicker{
	font-family:'niveau-bold';
	font-weight: 700}

.text-piersans {
	font-family: 'piersans';
}
.text-niveau
{
font-family:'niveau';
}

.text-niveau-bold
{
	font-family:'niveau-bold';
}

.text-width{
	width:80%;
}

.text-lineSpace{
	line-height: 1.2rem;
}

.para-margin p {
	margin-bottom: 1em;
}
