.steps {
  &__container {
    position: relative;
    height: 300px;
    width: 100%;
    @include tablet {
      height: 260px;
    }
    @include mobile {
      height: 570px;
    }
  }
  position: relative;
  height: 100%;
  //box-shadow: $mild-shadow;
  transition: transform $short $ease-in-out;
  &:last-child{ margin-right: 0 !important; }
  &:hover {
    transform: scale(1.005);
  }
  @include mobile {
    width: 80%;
    height: 170px;
    margin: 0 auto;
    margin-bottom: $base;
    padding: 0;
    transform: translateX(12.5%);
  }
  &__inner {
    width: 90%;
    margin: 0 auto;
  }
  &--header {
    display: block;
    height: 20%;
    margin: 0 auto;
    border-bottom: 2px solid $white;
    margin-bottom: $base;
    padding: $base 0;
    text-transform: uppercase;
    @include tablet {
      height: 10%;
      padding: 0;
    }
    @include mobile {
      margin-bottom: 0;
    }
  }
  &--content {
    display: block;
    height: 60%;
    margin: 0 auto;
    margin-bottom: $base;
    padding: $base 0;
    @include tablet {
      height: 50%;
    }
    @include mobile {
      padding: 0;
    }
  }
  &--button {
    position: absolute;
    display: block;
    height: 20%;
    width: 90%; // 3 column layout cta buttons - program accelerator
    bottom: 0%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    transition: box-shadow $medium $ease-in-out;
    @include tablet {
      height: 30%;
    }
    @include mobile {
      height: 40%;
    }
    .button {
      &:hover {
        //box-shadow: $shadow;
      }
    }
  }
}
