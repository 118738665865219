//FAQ ITEMS
.faq {
  a:hover { color: $yellow; }
  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: $base 5%;
    border: 1px solid $white;
    cursor: pointer;
    transition: all $medium $ease-in-out;
    margin-bottom: $base;
    &:after {
      content: '\25BC';
      color: $yellow;
      position: absolute;
      right: 5%;
      top: 20px;
      transition: all $medium $ease-in-out;
    }
    @include mobile {
      width: 90%;
      margin: $base auto;
    }
    &:first-child { margin-top: 0; }
    &-question {
      border-bottom: 1px solid $blue;
      p { margin: 0; }
      @include mobile {
        width: 95%;
        p { margin: 5px 0; }
      }
    }
    &-answer {
      height: 0px;
      opacity: 0;
      overflow: hidden;
      transition: height 500ms ease;
    }
  }
  &--blurb {
    margin: 2.5rem auto;
  }
}
.active-faq {
  display: flex;
  align-items: center;
  height: 80px;
  opacity: 1;
  .faq__item:after {
    opacity: 0;
  }
  @include mobile {
    height: 110px;
  }
}
.factive {
  background-color: $white;
  color: $blue;
  border: 1px solid $white;
  &:after {
    transform: rotate(-180deg);
  }
}
