//Hoverblock
.hoverblock {
  // &:before {
  //   content: '';
  //   @include abfab;
  //   filter: brightness(80%);
  //   display: block;
  //   @include bg-image();
  //   background-image: url(/assets/images/hover--one.jpg);
  //   z-index: 0;
  //   overflow: hidden;
  //   transition: all $medium $ease-out;
  //   // &:nth-child(1) { background-image: url(/assets/images/hover--one.jpg); }
  //   // &:nth-child(2) { background-image: url(/assets/images/hover--two.jpg); }
  // }
  position: relative;
  height: 420px;
  width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  //box-shadow: $shadow;
  transition: all $short $ease-in-out;
  @include bg-image();
  margin-bottom: 5rem;
  @include tablet {
    margin: 5rem auto;
    height: 380px;
  }
  @include mobile {
    width: 90%;
    height: auto;
    padding: $base;
  }
  &__inner {
    z-index: 1;
    display: inline-block;
    width: 90%;
    height: 75%;
    margin: 0 auto;
    padding: $base;
    @include tablet {
      padding: 0;
    }
  }
  &--left {
    display: block;
    width: 40%;
    float: left;
    margin-right: 5%;
    &-heading {
      font-size: $heading-size;
      padding-bottom: $base;
      border-bottom: 1px solid $white;
      margin-bottom: $base;
      @include mobile {
        width: 100%;
        float: left;
        border: none;
        margin-bottom: 0;
      }
    }
    &-note {
      font-size: $heading-size/1.5;
      @include mobile {
        //display: none;
        width: 100%;
        float: left;
        font-size: 1rem;
      }
      &-list {

      }
      &-item {
        margin-bottom: $base;
        @include tablet {
          list-style: circle;
          margin-left: $base;
        }
      }
    }
    @include mobile {
      width: 100%;
      padding-top: 0;
    }
  }
  &--right {
    float: left;
    width: 55%;
    height: 120%;

    @include mobile  {
      width: 100%;
    }
    &-inner {
      .border {
        border: 0;
        background-color: $yellow;
        height: 0;
        position: absolute;
        width: 0;
       }

      .topbar {
        left: 0;
        top: 0;

        -webkit-transition: width 0.4s ease-in-out;
        -moz-transition: width 0.4s ease-in-out;
        -o-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;

        @include tablet {
          height: 4px;
          width: 100%;
        }
        @include mobile {
          height: 0;
          width: 0;
        }
      }
      .rightbar {
        right: 0;
        top: 0;

        -webkit-transition: height 0.2s ease-in-out 0.4s;
        -moz-transition: height 0.2s ease-in-out 0.4s;
        -o-transition: height 0.2s ease-in-out 0.4s;
        transition: height 0.2s ease-in-out 0.4s;

        @include tablet {
          height: 100%;
          width: 4px;
        }
        @include mobile {
          height: 0;
          width: 0;
        }
      }
      .bottombar {
        bottom: 0px;
        right: 0px;

        -webkit-transition: width 0.2s ease-in-out 0.6s;
        -moz-transition: width 0.2s ease-in-out 0.6s;
        -o-transition: width 0.2s ease-in-out 0.6s;
        transition: width 0.2s ease-in-out 0.6s;

        @include tablet {
          height: 4px;
          width: 100%;
        }
        @include mobile {
          height: 0;
          width: 0;
        }
      }
      .leftbar {
        bottom: 0px;
        left: 0px;

        -webkit-transition: height 0.2s ease-in-out 0.8s;
        -moz-transition: height 0.2s ease-in-out 0.8s;
        -o-transition: height 0.2s ease-in-out 0.8s;
        transition: height 0.2s ease-in-out 0.8s;

        @include tablet {
          height: 100%;
          width: 4px;
        }
        @include mobile {
          height: 0;
          width: 0;
        }
      }

      position: relative;
      height: 120%;
      padding: $base*3;
      opacity: 1;
      filter: none;
      line-height: 1.5;
      //transition: all $medium $ease-out;
      @include tablet {
        opacity: 1;
        height: 110%;
        padding: $base*2;
      }
      @include mobile {
        opacity: 1;
        width: 100%;
        height: auto;
        border: none;
        padding: 0;
      }
    }
    @include mobile {
      padding: 0;
    }
  }
  &--content {
    height: 60%;
    opacity: 0;
    line-height: 1.5;
    -webkit-transition: opacity 0.3s ease-in-out 0.5s;
    -moz-transition: opacity 0.3s ease-in-out 0.5s;
    -o-transition: opacity 0.3s ease-in-out 0.5s;
    transition: opacity 0.3s ease-in-out 0.5s;

    @include mobile {
      opacity: 1;
      padding: $base;
    }
  }
  &--button {
    width: 100%;
    height: 40%;
    margin: $base auto;
    opacity: 0;
    text-align: center;

    -webkit-transition: opacity 0.3s ease-in-out 0.5s;
    -moz-transition: opacity 0.3s ease-in-out 0.5s;
    -o-transition: opacity 0.3s ease-in-out 0.5s;
    transition: opacity 0.3s ease-in-out 0.5s;

    @include tablet {
      opacity: 1;
    }
  }
}

.hoverblock::after {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}

.hoverblock {
  .hoverblock--right-inner
  {
    .hoverblock--content {
      opacity: 1;
    }
    .topbar {
      height: 4px;
      width: 100%;
    }
    .rightbar {
      height: 100%;
      width: 4px;
    }
    .bottombar {
      height: 4px;
      width: 100%;
    }
    .leftbar {
      height: 100%;
      width: 4px;
    }
    .hoverblock--button {
      opacity: 1;
    }
  }
}

div.hoverblock:nth-child(1){
  background-image: // hard coded to use the same bg image, fix the perch issue and display the other bg images using JavaScript
  linear-gradient(rgba(0,0,0,0.4), transparent 100%),
  linear-gradient(360deg, rgba(0,0,0,0.4), transparent),
  url(/perch/resources/business-profile/consultancy-strategic-planning.jpg) !important;
}

div.hoverblock:nth-child(2){
  background-image: // hard coded to use the same bg image, fix the perch issue and display the other bg images using JavaScript
  linear-gradient(rgba(0,0,0,0.4), transparent 100%),
  linear-gradient(360deg, rgba(0,0,0,0.4), transparent),
  url(/perch/resources/business-profile/consultancy-growth-hack.jpg) !important;
}

div.hoverblock:nth-child(3){
  background-image: // hard coded to use the same bg image, fix the perch issue and display the other bg images using JavaScript
  linear-gradient(rgba(0,0,0,0.4), transparent 100%),
  linear-gradient(360deg, rgba(0,0,0,0.4), transparent),
  url(/perch/resources/business-profile/consultancy-acceleration.jpg) !important;
}
