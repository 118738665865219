@font-face {
  font-family: 'piersans';
  src: url('../assets/fonts/PierSans-Bold.eot');
  src: url('../assets/fonts/PierSans-Bold.eot') format('embedded-opentype'),
       url('../assets/fonts/PierSans-Bold.woff') format('woff'),
       url('../assets/fonts/PierSans-Bold.ttf') format('truetype'),
       url('../assets/fonts/PierSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'niveau-bold';
    src: url('../assets/fonts/NiveauGrotesk-bold.eot');
    src: url('../assets/fonts/NiveauGrotesk-bold.woff2') format('woff2'),
       url('../assets/fonts/NiveauGrotesk-bold.woff') format('woff'),
       url('../assets/fonts/NiveauGrotesk-bold.ttf') format('truetype'),
       url('../assets/fonts/NiveauGrotesk-bold.svg') format('svg');
  font-weight: bold;
  font-style: bold;
}

@font-face {
    font-family: 'niveau';
    src: url('../assets/fonts/NiveauGrotesk-regular.eot');
    src: url('../assets/fonts/NiveauGrotesk-regular.woff2') format('woff2'),
       url('../assets/fonts/NiveauGrotesk-regular.woff') format('woff'),
       url('../assets/fonts/NiveauGrotesk-regular.ttf') format('truetype'),
       url('../assets/fonts/NiveauGrotesk-regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
