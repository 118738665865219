.contact__outerContainer {
  //background-color: $white;
}
.contact__header {
  height: 80vh;
}
#contact-form {
  input {
    background-color: $blue;
    border: none;
    color: $white;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
    color: $white !important;
  }

  .contact-submit-button {
    background-color: $yellow;
    border-style: none;
    color: $white;
    padding: 10px 40px;
  }
  .form__group-name {
    border-top: 1px solid $white;
    .form__group-col {

      display: inline-block;
      width: 49%;
      @include mobile {
        width: 100%;
      }
      &:first-child {
        border-right: 1px solid $white;
      }
    }
  }
  .form__group-contactInfo {
    border-top: 1px solid $white;
    //margin-top: 30px;
    .form__group-col {

      display: inline-block;
      width: 59%;
      @include mobile {
        width: 100%;
      }
      &:first-child {
        border-right: 1px solid $white;
        width: 40%;
        @include mobile {
          width: 100%;
        }
      }
    }
  }
  .contact-submit-container {
    //margin-top: 30px;
    text-align: center;
  }
}
