.ecosystem {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.3rem;
  margin-bottom: 3.5rem;
  padding-top: 3rem;

  &__image {
    width: 50%;
    div {
      background-repeat: no-repeat;
      background-size: contain;
      height: 100vh;
      width: 100%;
      background-position: center;
      @include tablet{
        height:70vh;
      }
      @include mobile{
        background-size: cover;
        height: 100%;
        background-position: 90% 100%;
      }
    }
  }
  &__content {
    width: 50%;
  }
  &__box {
    padding: 1rem;
    background-color: $white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    z-index: 1;
    position: relative;
  }
  &__content-inner {
    width: 70%;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      width: 80%;
      height: calc(100% + 2rem + 16px);
      position: absolute;
      left: 40%;
      top: 0;
      border: 1px solid $black;
      z-index: 0;
    }
    @include tablet {
      width: 90%;
    }
  }
  &__link {
    float: right;
    text-decoration: none;
    position: relative;
    z-index: 100;
    transition: color 0.3s ease;

    &:hover {
      color: $white;
    }
  }
}


//image-text
.image-text {
  display: flex;
  flex-flow: row;
  width: 100%;
  min-height: 100vh;
  @include mobile {
    flex-wrap: wrap;
  }
  &__image {
    width: 55%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include tablet {
      width: 45%;
    }
    @include mobile {
      width: 100%;
      order: 2;
    }

  }
  &__content {
    width: 45%;
    padding-left: 2rem;
    padding-right: 4rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @include tablet {
      width: 55%;
      padding-right: 2rem;
      padding-left: 1rem;
    }
    @include mobile {
      width: 100%;
      order: 1;
    }
  }
}


//team
.team {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 2rem;
  &__member{
    width: 33%;
    margin-bottom: 3rem;
    @include tablet{
      width: 50%;
    }
    @include mobile{
      width: 90%;
    }
  }
  &__photo{
    height: 9rem;
    width: 9rem;

    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(../assets/images/team-bg.svg);
    margin-bottom: 1.5rem;
    position: relative;
    @include mobile{
        height: 15rem;
        width: 15rem;
    }
  }
  &__image {
    height: 6.9rem;
    width: 6.9rem;
    border-radius: 6.9rem;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 1rem;
    left: 1rem;
    z-index: -1;
    @include mobile{
        height: 13rem;
        width: 13rem;
        border-radius: 13rem;
    }
  }
  &__name{
    //@include font-bold;
    @extend .text-medium;
    @extend .text-thicker;
  }
  &__position{
    @extend .text-small;
    color: $black;
    @include mobile{
      margin-bottom: 2rem;
    };
  }
  &__linkedin{
    display: inline-block;
    text-decoration: none;
    color: $white;
    background: rgba($black, 1);
    border: 1px solid $white;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    //padding-left: 0;
    border-radius: 2rem;
    transition: background 330ms linear;
    margin-top: 2rem;
    position: absolute;
    left: calc(50% - 0.75rem);
    top: 57%;
    @include mobile{
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
      border-radius: 4rem;
      margin-bottom: 2rem;
      margin-top: 0;
      left: calc(50% - 1.5rem);
      top: 75%;
    }
    &:hover{
      background: rgba($black, 1);
    }
    .icon-linkedin {
      font-size: 16px;
    }
  }
}


//text-image
.text-image {
  width: 55%;
  @include mobile {
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 40vh;
  }

  &__content {
    padding-right: 1rem;
    @include mobile {
      padding-right: 0;
    }
  }
}
.text-image__image {
  width: 45%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  @include tablet {
    width: 40%;
  }
  @include mobile {
    width: 100%;
    height: 40vh;
    bottom: 0;
    top: auto;
  }
}

//events
.events-outer {
  background-color: rgba($yellow, 0.8);
}
.events {
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
  @include mobile {
    flex-wrap: wrap;

  }
  &__item {
    width: 50%;
    margin-bottom: 2rem;
    padding: 0 1rem;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      width: 100%;
    }
  }
  &__date {
    width: 25%;

    background-image: url('../assets/images/events-date-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 50px;
    //width: 50px;
    p {
      padding-top: 17px;
      padding-left: 5px;
      @include mobile
      {
        padding-left: 10px;
      }
    }
  }
  &__description {
    width: 50%;

    border-right: 1px solid $black;
  }
  &__name {
    padding-bottom: 0.5rem;
  }
  &__link {
    width: 25%;
    p {
      padding-left: 0.5rem;
    }
  }
}

//news

.news {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__item {
    width: 31%;
    // display: flex;
    // flex-flow: column;
    position: relative;
    margin-bottom: 2rem;

    @include tablet {
      width: 48%;
    }
    @include mobile {
      width: 100%;
    }
    &:after {
      content: '';
      width: 45%;
      height: 100%;
      top: 0;
      right:0;
      position: absolute;
      background-color: rgba($white, 0.4);
    }
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 40vh;
  }

  &__description {
    padding-top: 1rem;
    p {
      padding-right: 1rem;
    }
  }
  &__link {
    text-decoration: underline;
  }
}

//Form before footer
.border-top {
  border-top: 2px solid $dark-grey;
}

.margin-top-large
{
  margin-top: 10vh;
}
