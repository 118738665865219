//STYLED HEADINGS
$borderWidth: 2px;
.heading {
  position: relative;
  width: 100%;
  height: 10vh;
  border: $white;
  border-top: $borderWidth solid;
  border-left: $borderWidth solid;
  padding: $base;
  @include mobile {
    width: 90%;
    margin: 0 auto;
  }
  &:before {
    content: '';
    position: absolute;
    top: -$borderWidth;
    left: -10%;
    width: 10%;
    height: 100%;
    border-top: $borderWidth solid;
  }
  &:after {
    content: '';
    position: absolute;
    top: -$borderWidth;
    right: -10%;
    width: 10%;
    height: 100%;
    border-top: $borderWidth solid;
  }
  &--dark-grey {
    border: $dark-grey;
  }
}
