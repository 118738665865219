#ouibounce-modal{
      z-index: 999;
      .modal
      {
          height: 300px !important;
      }

      .footer--form
      {
        left: 45%;
      }

      #form1_footerSubmit
      {
        background-color: $white;
        color: $black;
        border-radius: 0px;
        border-top: 1px solid $black;
        border-right: 1px solid $black;
        border-bottom: 1px solid $black;
      }

      @include mobile
      {
        .modal-body{
          width: 350px !important;
        }

        .footer--form
        {
          left:0%;
        }
        #form1_footerSubmit
        {
          border-left: 1px solid $black;
        }
      }
}


.header {
  position: relative;
  content: '';
  height: 100vh;
  width: 100vw;
  //position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  &__inner {
    z-index: 1;

    height: auto;
    width: 85%;

    @include tablet {
      width: 100%;
      height: auto;
    }
  }
  &--shape { background-image: url(/assets/images/header-bg.jpg); }
  &--heading {
    width: 40%;
    padding: $base*2;

    @include tablet{
      width: 85%;
      height: auto;
      // float: none;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // border-bottom: 2px solid $white;
    }
    @include mobile {
      //height: 10%;
    }
    h1 {
      font-size: 48px;
      text-align: left;
      font-weight: 600;
      margin-bottom: 2rem;
      font-family: 'piersans';

    }
  }

  &--rectangle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    width: 55%;
    @include bg-image();
    //background-image: url(/assets/images/Consultancy-startup-melbourne-hatch-quarter-acceleration.jpg);
    //filter: saturate(60%);
    @include tablet {
      //width: 65%;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    p {
      height: auto;
      width: 85%;
      margin: auto;
      margin-bottom: 0.5em;

      padding: $base*2;
      padding-bottom: 0.5rem;

      @include tablet {
        width: 100%;
      }
    }
  }
  &__footer-links {
    background-color: rgba($white, 0.65);
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    @include mobile {
      background-color: transparent;
    }
  }
  &__footer-link {
    color: darken($dark-grey, 40%);
    font-size: 17px;
    transition: color 0.5s ease;
    @extend .text-small;
    &:hover {
      cursor: pointer;
      color: $white;
    }
    @include mobile {
      color: $white;
      margin-bottom: 1rem;
    }
  }
  &__footer-links-inner {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    width: 85%;
    margin: auto;
    padding-left: $base*2;
    padding-right: $base*2;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      flex-flow: column;

    }

  }


}
.medium-header {
  position: relative;
  content: '';
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-grey;
  background-attachment: fixed;
  background-image:
  linear-gradient(#4b4b4b, transparent 150%),
  linear-gradient(0deg, #4B4b4b, transparent),
  url(/perch/resources/business-profile/consultancy-strategic-planning.jpg) !important;
  @include mobile {
    height: 90vh;
  }
  &--heading {
    width: 100%;
    padding: $base*2;
    text-align: left;
    text-transform: uppercase;
    float: right;
    margin-bottom: $base*2;
    @include mobile {
      width: 90%;
      margin: 0 auto;
      height: 10%;
      padding: $base;
      margin-bottom: $base;
    }
    h1 {
      font-size: 3rem;
      @include tablet {
        text-align: center;
      }
      @include mobile {
        margin-left: -$base*2;
      }
    }
  }
  &--summary {
    width: 100%;
    font-size: $large-size;
    padding: $base*2 0;
    line-height: 1.7;
    @include mobile {
      width: 80%;
      height: 60%;
      margin: 0 auto;
      margin-top: $base;
      p { font-size: $small-size*1.25; }
    }
  }

}
.small-header {
  position: relative;
  content: '';
  height: 40vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-grey;
}

@include mobile{
.blog--header .header--heading h1
{
  font-size:30px;
}}