.accordian{
  cursor: pointer;
  user-select: none;
}
.accordian--init:not(.accordian--open) .accordian__content{
  height: 0 !important;
}
.accordian--init .accordian__content{
  transition: height $short $ease-out;
  overflow: hidden;
}
