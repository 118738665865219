$susy: (
  columns: 12,
  gutters: 0,
  math: fluid,
  output: float,
  gutter-position: inside,
);

.row{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}
//DESKTOP LAYOUT GRIDS
.col {
  &-half{ @include span(50%); }
  &-third {
    @include span(4);
    height: 100%;
    // display: flex;
    // margin-right: 5%;
    // &:nth-child(3) { margin-right: 0; }
  }
  &-two-thirds {
    @include span(60%);
    height: 100%;
  }
  &-fifth {
    @include span(20%);
    &--double { @include span(40%); }
  }
  &-faq-categories {
    @include span(20%);

  }
  &-testimonial {
    @include span(70%);
  }
  &-steps {
    @include span(30%);
    height: 100%;
    margin-right: 5%;
    &:nth-child(4) { margin-right: 0; }
  }
}

@include tablet {
  .col-third,
  .col-two-thirds {
    @include span(100%);
  }
}

//ADAPTING TO MOBILE GRIDS
@include mobile(){
	.col-half,
  .col-half--right,
  .col-half--mid,
  .col-faq-categories,
  .col-faq-items {
		@include span(100%);
		margin-left: 0;
	}
}
