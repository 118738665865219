//COLORS
$light-grey: #D3D3D3;
$medium-grey: #898989;
$dark-grey:#4B4B4B;
$blue: #355C7D;
$black: #4B4B4B;
$yellow: #FBC926;
$white: white;
$bg-grey: #ebebeb;
$bg-grey2: #D1D6D6;
$lightest-grey: #ebebeb;

$blue-gradient: linear-gradient(to right, $black, $blue);
$shadow: 3px 3px 3px 1px rgba(0,0,0,0.50);
$mild-shadow: 1.5px 1.5px 2.5px 0px rgba(0,0,0,0.50);
$blue-gradient-mild: linear-gradient(to right, $blue, #215077);


//DIMENSIONS
$base: 18px;
$mobile-base: 5vw;
$content-width: 960px;
$section-pad: 6rem;
$bor-width: 2px;
$heading-width: 1160px;

//FONTS
$ratio: 1.414;
$small-size: 1rem/$ratio;
$body-size: 1rem;
$large-size: 1rem*$ratio;
$heading-size: 1rem*$ratio*$ratio;
$huge-size: 1rem*$ratio*$ratio*$ratio;

//TIMINGS
$short: 200ms;
$medium: 600ms;
$long: 1200ms;

$ease-in: cubic-bezier(0.895, 0.030, 0.685, 0.220);  /* custom */
$ease-out: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* custom */
$ease-in-out: cubic-bezier(0.770, 0.000, 0.175, 1.000); /* custom */
