* { box-sizing: border-box; }

html{
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-size: $base;
  scroll-behavior: smooth;
  @include large-tablet{
    font-size: $base;
  }
  @include mobile{
    font-size: $base;
  }
}

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  font-weight: 300;
  overflow: hidden;
  font-family: "niveau";
  color: $black;
}

p{
  margin: 0;
}

b, strong{
  font-weight: 900;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

h1,h2,h3,h4,h5,h6{
	font-size: inherit;
	margin: 0;
	font-weight: inherit;
}

li, ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}


*{
	// outline: none !important; //use this line to toggle the outlines
}
#main { z-index: 1; }
