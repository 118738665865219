//BANNER
.scrolling-banner {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: $yellow;
  padding: $base 0;
  font-size: 60px;
  font-weight: 800;
  position: relative;
  @include mobile{
    padding: 0;
    min-height: 5rem;
  }
  &-content {
    margin: 0;
    padding: 0;
    height: auto;
    color: $black;
    font-weight: 900;
    width: auto;
    white-space: nowrap;
    position: absolute;
    text-align: center;
    transform: translateX(100%);
    animation: scroll-left 20s linear infinite;
    min-width: 100%;
    transition: transform 1600ms $ease-out;
    // @include tablet {
    //   width: 295%;
    //   animation: scroll-left 10s linear infinite;
    // }
    // @include mobile {
    //   width: 600%;
    // }
    &:after{
      content: '→';
      position: relative;
      display: inline-block;
      margin-left: 40px;
      transition: transform 1600ms $ease-out;
    }
  }
  &:hover{
    .scrolling-banner-content:after{
      transform: translateX(100px);
      transition: transform 6400ms $ease-out;
    }
  }
}
@keyframes scroll-left {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
