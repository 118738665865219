$placeholderOpacity: 0.8;
$inputHeight: 45px;
$focusOpacity: 0.4;

.select-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  &:after {
    content: '\25BC';
    position: absolute;
    color: grey;
    display: block;
    height: 30px;
    width: 30px;
    right: 5%;
    top: 25px;
    transition: all $medium $ease-in-out;
    z-index: 0;
  }
}
select::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
}

.form {

  &__input {

    transition: all $medium $ease-in-out;
    &-text{
      -webkit-apperance: none !important;
      border-radius: 0 !important;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: $base/2;
      color: grey;
      background-color: $white;
      border: none;
      outline: none;

      //border-top: 1px solid $black;
      //border-left: 1px solid $black;
      border-bottom: 1px solid $black;
      @include mobile
      {
        border-right: 1px solid $black;
      }

      border-bottom: 1px solid $light-grey;
      @extend .text-small;

      &::placeholder {
        color: grey;
        opacity: $placeholderOpacity;
      }
      &:focus {
        &::placeholder {
          opacity: $focusOpacity;
        }
      }
    }

    &-select {
      -webkit-apperance: none !important;
      border-radius: 0 !important;
      position: relative;
      width: 100%;
      height: 45px;
      padding: $base/2;
      appearance: none;
      background-color: transparent;
      color: grey;
      outline: none;
      border-radius: 0;
      border: 1px solid $light-grey;
      cursor: pointer;
      z-index: 1;
      @extend .text-small;

      &-main {
        border: 1px solid $light-grey;
        //box-shadow: $mild-shadow;
        transition: box-shadow $medium $ease-in-out;
        &:hover {
          //box-shadow: $shadow;
        }
        .select-wrapper {
          &:after {
            content: '\25BC';
            color: $yellow;
            right: 5%;
            top: 17px;
            display: block;
            transition: all $medium $ease-in-out;
          }
        }
      }
      &::placeholder {
        opacity: $placeholderOpacity;
      }
      &:focus {
        &::placeholder {
          opacity: $focusOpacity;
        }
      }
      &[disabled], &:disabled{
        opacity: $placeholderOpacity;
        cursor: not-allowed;
      }
    }

    &-textarea {
      font-size: 14px;
      -webkit-apperance: none !important;
      border-radius: 0 !important;
      width: 100%;
      height: 135px;
      background-color: transparent;
      outline: none;
      border: none;
      color: grey;
      padding: $base/2;
      border: 1px solid $black;
      //border-left: 1px solid $white;
      &[id="form1_contactMessage"], &[id="form2_programObjectives"] { // I'm sure theres a better way of doing this
        //border-bottom: 1px solid $white;
      }
      &::placeholder {
        color: grey;
        opacity: $placeholderOpacity;
      }
      &:focus {
        &::placeholder {
          opacity: $focusOpacity;
        }
      }
    }

    &-upload {
      width: 0.1px;
    	height: 0.1px;
    	opacity: 0;
    	overflow: hidden;
    	position: absolute;
    	z-index: -1;
      &-label {
        position: relative;
        height: 45px;
        width: 100%;
        color: $black;
        border: none;
        outline: none;
        opacity: $placeholderOpacity;
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        padding: $base/2;
        background-color: transparent;
        display: inline-block;
        cursor: pointer;
        margin-bottom: 1rem;
        &:after {
          content: '\21E7';
          height: 100%;
          width: 5%;
          position: absolute;
          right: 0;
          top: 25%;
        }
      }
    }

    &-checkbox {
      appearance: none;
      position: relative;
      display: inline-block;
      outline: none;
      border: none;
      &:after {
        content: '';
        border: 1px solid $yellow;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
      }
      &:checked {
        outline: none;
        position: absolute;
        background-color: $yellow;
        border: none;
        height: 21px;
        width: 21px;
      }
      &-label {
        margin: 0;
        padding: 0;
        margin-left: 50px;
        height: 100%;
      }
    }

    &-date {
      position: relative;
      height: 45px;
      width: 100%;
      color: grey;
      border: none;
      outline: none;
      opacity: $placeholderOpacity;
      border-top: 1px solid $white;
      border-left: 1px solid $white;
      padding: $base/2;
      background-color: transparent;
      display: inline-block;
      cursor: pointer;
    }

    &-submit {
      // transform: translateX(175%);
      @include tablet {
        width: 100%;
        // transform: translateX(50%);
      }
    }

  }
}

.apply-form {
  opacity: 1;
  pointer-events: auto;
  transition: all $medium $ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  &__wrap {
    position: relative;
    width: 100%;
    //height: 55vh;
  }
}

.form--hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity $medium $ease-in-out;
}
.start--pos {
  transform: translateY(50px);
  opacity: 0;
}
.application--form {
  transform: translateY(-8rem);
  z-index: 1;
  position: relative;
  padding: 4rem 2rem;
  overflow: hidden;
  @include mobile {

  }
}
.application--tagline {
  height: 280px;
  padding: 2.5rem 6rem;
  transform: translateY(-150px);
}

.footer--form{
  left: 50%;
top: 50%;
margin-left: -25% !important;
position: relative;

@include mobile
{
  width: 100% !important;
  left:0;
  margin-left: 0 !important;
}

  top: 50%;
  margin-left: -25% !important;
  position: relative;
  .form__input-text {
    height: 45px;
    line-height: 45px;
    border-top: 1px solid $dark-grey;
    border-left: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    @extend .text-small;
  }
}
.contact-label {
  @extend .text-small;
  color: grey;
  margin-bottom: 1rem;

}

.footer--form-submit{
  height:45px;
  background: $dark-grey;
  color: $white;
  transition: all 0.5s ease;

  &:hover {
    background: $yellow;
    color: $dark-grey;
  }
}

.noBorderTop
{
  border-top: 0px solid !important;
}

.form-divider {
  min-height: 2px;
  margin-bottom: 12rem;
}
