.section {
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  height: auto;
  padding: 5rem 0;
  @include tablet {
    padding: 2rem 0;
  }
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  &__inner {
    @include container;
    @include span($content-width);
    float: none;
    position: relative;
    max-width: 85vw;
    margin: 0 auto;
    // padding: 5rem 0;
    @include mobile() {
      max-width: 85vw;
      padding: 0;
    }
    &-small {
      float: none;
      position: relative;
      max-width: 55vw;
      margin: 0 auto;
      @include tablet {
        max-width: 85vw;
      }
    }
    &-footer {
      max-width: 55vw;
    }

    // p {
    //   margin-bottom: 1em;
    // }
  }
  &--full {
    //min-height: 100vh;
    height: auto;
    width: 100%;
    padding: 5rem 0;
    &-less {
      height: auto;
      min-height: 75vh;
      @include tablet {
        height: auto;
        min-height: 60vh;
        padding-bottom: $base;
      }
    }
  }
  &--half {
    min-height: 50vh;
    height: auto;
    width: 100%;
    outline: 1px solid black;
  }
  &--contact {
    min-height: 70vh;
  }
  &--strip {
    height: auto;
    min-height: 280px;
    @include tablet {
      //min-height: 35vh;
    }
  }
  &--header {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
  &--index-height {
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
  &--noPad{
    padding: 0;
  }
  &--pad-half {
    padding: 2.5rem 0;
  }

  &--bgpattern {
    background-image: url('../assets/images/events-bg-pattern.svg');
    background-repeat: repeat;
    background-size: 150px 150px;
  }
  &--bgpattern-light {
    background-image: url('../assets/images/get-to-know-us-bg-pattern.svg');
    background-repeat: repeat;
    background-size: 150px 150px;
  }
}
