@mixin bg-image() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin bg-contain() {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin centerme{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
}

@mixin abfab{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin mobile() {
	@media screen and (max-width: 450px) {
		@content;
	}
}

@mixin small-mobile() {
	@media screen and (max-width: 360px) {
		@content;
	}
}

@mixin tablet() {
  @media screen and (max-width: 768px) {
		@content;
	}
}

@mixin med-tablet() {
  @media screen and (max-width: 910px) {
		@content;
	}
}

@mixin large-tablet() {
  @media screen and (max-width: 980px) {
		@content;
	}
}

@mixin small-desk() {
  @media screen and (max-width: 1080px) {
		@content;
	}
}

@mixin desk() {
	@media screen and (min-width: 450px) {
		@content;
	}
}

@mixin shadow-high() {
  //box-shadow: 0px 0px 20px -6px rgba($black,0.5);
}

@mixin shadow-mid() {
  //box-shadow: 0px 0px 12px -4px rgba($black,0.5);
}

@mixin shadow-low() {
  //box-shadow: 0px 0px 6px -1px rgba($black,0.5);
}
