//BUTTONS
.button{
  //height: 36px;
  //width: 100%;
  padding: 0.5rem 3rem;
  outline: none;
  border: none;
  text-transform: uppercase;
  line-height: 100%;
  font-size: 12px;
  //font-weight: 500;
  @extend .text-niveau-bold;
  letter-spacing: 0.1em;
  color: $white;
  cursor: pointer;
  background-color: transparent;
  transition: all $short $ease-out;
  //box-shadow: $mild-shadow;
  &:hover {
    //box-shadow: $shadow;
    // opacity: 0.9;
  }
  &--yellow{ 
    background-color: $yellow; 
    color: $black;
    border: 1px solid rgba($yellow, 0);
    transition: all 0.5s ease-out;

    &:hover {
      background-color: $black; 
      color: $yellow;
      border: 1px solid rgba($yellow, 1);
    }
  }
  &--white{
    border-top: 1px solid $black;
    border-right: 1px solid $black;
    border-bottom: 1px solid $black;
    background-color: $white;
    color:$black;
    @include mobile
    {
      border-left: 1px solid $black;
    }
  }
  &--blue{ background-color: $blue; }
  &--outline{ outline: 1px solid $white; }
  &--outline-yellow{
    background-color: transparent;
    color: $dark-grey;
    outline: 1px solid $yellow;
  }
  &-large {
    height: 60px;
    width: 180px;
  }

  &-margintop
  {
    margin-top:1rem;
  }
}
