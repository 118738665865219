.whoWeAre {
&__inner {
  margin: $base auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  height: auto;
  @include tablet {
    flex-wrap: wrap;
  }
}
&--card {
  height: 240px;
  background-color: $light-grey;
  padding: $base;
  //box-shadow: $mild-shadow;
  @include span(20%);
  &:first-child {
    margin-left: 2.5%;
    @include tablet {
      margin-left: 0;
    }
  }
  @include tablet {
    @include span(45%);
    margin-right: 10%;
    margin-bottom: 2rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @include mobile {
    height: 250px;
  }
  &-heading {
    padding-bottom: $base;
    margin-bottom: $base;
    border-bottom: 1px solid grey;
  }
  &-headingWhite {
    padding-bottom: $base;
    margin-bottom: $base;
    border-bottom: 1px solid white;
  }
}
&--card3 {
  height: 240px;
  background-color: $light-grey;
  padding: $base;
  //box-shadow: $mild-shadow;
  @include span(22%);
  &:first-child {
    margin-left: 8%;
    @include tablet {
      margin-left: 0;
    }
  }
  @include tablet {
     @include span(30%);
     margin-right: 5%;
     margin-bottom: 2rem;
     &:nth-child(3n) {
       margin-right: 0;
     }
  }
  @include mobile {
    height: 200px;
    @include span(70%);
    margin: auto;
    margin-bottom: 2rem;
    &:first-child {
      margin-left: auto;
    }
    &:nth-child(3n) {
      margin-right: auto;
    }


  }
  &-heading {
    padding-bottom: $base;
    margin-bottom: $base;
    border-bottom: 1px solid grey;
  }
  &-heading-white {
    padding-bottom: $base;
    margin-bottom: $base;
    border-bottom: 1px solid white;
  }
}
}

/* .blurb {
  p {
    width: 100%;
  }
} */
// .blurb {
//   transition: all 1s;
// }
//
// .blurb-column-1, .blurb-column-2 {
//   max-width: 80%;
//   margin: 0 auto;
//   padding: 60px 0;
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: center;
//   @include large-tablet {
//     display: flex;
//     flex-direction: column !important;
//   }
// }
//
// .blurb-column-1 {
//   .blurb-text {
//     margin-left: 3rem;
//   }
//   @include large-tablet {
//     .blurb-text {
//       margin-left: 0rem;
//     }
//   }
// }
// .blurb-column-2 {
//   .blurb-text {
//     margin-right: 3rem;
//   }
//   @include large-tablet {
//     .blurb-text {
//       margin-left: 0rem;
//     }
//   }
// }
//
// .blurb-image {
//   width: 50%;
//   //box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px 1px;
//   @include large-tablet {
//     width: 100%;
//   }
// }
//
// .blurb-column-2 {
//   .blurb-image {
//     order: 1;
//   }
//   @include large-tablet {
//     .blurb-image {
//       order: 0;
//     }
//   }
// }


//REFACTORED STYLES



.blurb {
  position: relative;
  transition: all $long $ease-in-out;
  max-width: 80%;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  @include large-tablet {
    display: flex;
    flex-direction: column !important;
  }

  &-text {
    margin: 0;
    margin-right: 60px;
    @include large-tablet {
      margin: 0 !important;
    }
  }

  &-image {
    display: block;
    min-width: calc(50% - 30px);
    min-height: 110%;
    @include bg-image;
    @include large-tablet {
      width: 100%;
      height: 20vh;
      margin-top: 2rem;
    }

  }


  &--left{
    flex-direction: row-reverse;
    .blurb-text{
      margin-right: 0;
      margin-left: 60px;
    }
  }

}

.blockquote{
  text-align: center;
  padding: 1rem 5rem;
  font-size: 48px;
  @include large-tablet {
    font-size: 36px;
    padding: 0 1rem;
  }
  @include mobile{
    font-size: 24px;
  }
}
