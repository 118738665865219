$t: 800ms;

.hb {

  position: relative;

  height: 100vh;
  width: 100vw;
  color: $black;
  background-color: $bg-grey;

  @include tablet {
    padding: 5rem 0 1.5rem;
    height: auto;
  }

  &__inner{
    z-index: 2;
    height: 100%;
    width: 85%;
    margin: auto;
  }
  &__heading {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 5rem - 100px);
    position: relative;
    @include tablet {
      height: auto;
      padding: 4rem 0;
    }
    @include mobile {
      height: auto;
      padding: 2rem 0;
    }
  }
  &__subtitle {
    position: relative;
    padding-left: 2rem;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1.5rem;
      height: 2px;
      background-color: #4b4b4b;
    }
  }

  &__footer {
    height: auto;
    border-top: 1px solid $black;
    padding-top: 1rem;
    .hb__links {
      width: 15%;
      display: inline-flex;
      font-size: 12px;
      margin-right: 1rem;
      padding-left: 1.5rem;
      position: relative;
      @include tablet {
        width: 50%;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 1rem;
      }
      &:before {
        content: '';
        height: 1rem;
        width: 1rem;
        background-image: url("../assets/images/hatch-quarter-banner-cta.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }
    .button {
      //width: auto;
      float: right;
      //padding: 0 0.5rem;
      @include mobile {
        float: none;
        margin: auto;
        margin-top: 1rem;
      }
    }
  }
  &__image {
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    height: calc(100% - 1rem);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 6vw;
    text-align: left;
    font-weight: 600;
    margin-bottom: 2rem;
    width: 100%;
    font-family: 'piersans';
    @include tablet {
      padding-top: 6rem;
      font-size: 9vw;
    }
    @include mobile {
      z-index: 1;
      padding-top: 2rem;
      font-size: 12vw;
    }
  }

  // &__wrap{
  //   //outline: 1px solid blue;
  //   opacity: 0;
  //   height: 20vw;
  //   width: 20vw;
  //   position: absolute;
  //   transition: transform 800ms $ease-out, border 300ms linear;
  //   padding: 1vw;
  //   border: transparent 0.5vw solid;
  //   &--cta-dummy{ transform: translate3d(-50vw, 10vw, 0);}
  //   &--left{ transform: translate3d(-20vw, -5vw, 0);}
  //   &--center{ transform: translate3d(7.5vw, 10vw, 0);}
  //   &--right{ transform: translate3d(35vw, -5vw, 0);}
  //   @include mobile{
  //     width: 80vw;
  //     &--cta-dummy{ transform: translate3d(-50vw, 50vw, 0);}
  //     &--left{ transform: translate3d(-22vw, -40vw, 0);}
  //     &--center{ transform: translate3d(-25vw, 0vw, 0);}
  //     &--right{ transform: translate3d(-25vw, 40vw, 0);}
  //   }
  //   &:after{
  //     content: '→';
  //     position: absolute;
  //     bottom: 1vw;
  //     right: -3vw;
  //     font-size: 6vw;
  //     font-weight: 900;
  //     opacity: 0;
  //     transform: translateX(-4vw);
  //     transition: transform 1600ms $ease-out, opacity 300ms linear;
  //   }
  // }
  // &__link{
  //   display: block;
  //   @include centerme;
  //   top: 40%;
  //   left: 40%;
  //   height: 40vh;
  //   width: 25vw;
  //   //background: red;
  //   z-index: 2;
  //   @include mobile{
  //     width: 80vw;
  //     height: 35vw;
  //   }
  // }
  // &__shape{
  //   //border: 3px solid $yellow;
  //   height: 15vw;
  //   width: 15vw;
  //   position: absolute;
  //   top: -3vw;
  //   left: -3vw;
  //   transform-origin: 6vw 6vw;
  //   transition: transform 2400ms $ease-in-out;
  //   //background: $yellow;
  //   //outline: 1px solid green;
  //   @include mobile{
  //     transform-origin: 0vw 0vw;
  //     transition: transform 800ms $ease-out;
  //   }
  //   svg{
  //     width: 100%;
  //     height: 100%;
  //   }
  //   svg rect, svg circle, svg polygon{
  //     stroke: $yellow;
  //     stroke-width: 2px;
  //     fill: $yellow;
  //     fill-opacity: 1;
  //     transition: fill-opacity 300ms linear;
  //   }
  // }
  // &__title, &__description{
  //   position: relative;
  //   z-index: 1;
  //   transition: opacity
  // }

  // &__title{
  //   font-size: 4vw;
  //   text-transform: uppercase;
  //   font-weight: 900;
  //   letter-spacing: 0.1em;
  //   line-height: 1;
  //   margin: 0;
  //   transition: transform 800ms $ease-out, opacity 300ms linear;
  //   @include mobile{
  //     font-size: 10vw;
  //   }
  // }
  // &__description{
  //   font-size: 2vw;
  //   font-weight: 900;
  //   line-height: 1;
  //   margin: 0;
  //   opacity: 0;
  //   transform: translateX(2vw);
  //   transition: transform 800ms $ease-out, opacity 300ms linear;
  //   @include mobile{
  //     font-size: 5vw;
  //   }
  // }
  //
  // &:not(.hb--logo){
  //   svg rect, svg circle, svg polygon{
  //     stroke-dasharray: initial !important;
  //     stroke-dashoffset: initial !important;
  //     animation: none !important;
  //   }
  //   .hb{
  //     &__wrap:not(.hb__wrap--cta-dummy):hover{
  //       border: $light-grey 0.5vw solid;
  //       &:after{
  //         opacity: 1;
  //         transform: translateX(0vw);
  //       }
  //       .hb{
  //         &__description{
  //           opacity: 1;
  //           transform: translateX(0vw);
  //         }
  //         &__shape{
  //           transform: scale(1.25);
  //           transition: transform 6400ms $ease-out;
  //         }
  //       }
  //     }
  //   }
  //   @include mobile{
  //     .hb__description{
  //       opacity: 1;
  //       transform: translateX(0vw);
  //     }
  //     .hb__shape{
  //       transform: scale(2);
  //     }
  //   }
  // }
  //
  // &--logo{
  //   .hb{
  //     &__wrap{
  //       &--cta-dummy{transform: translate3d(2.5vw, 2.5vw, 0);}
  //       &--left{transform: translate3d(10vw, 2.5vw, 0);}
  //       &--center{ transform: translate3d(17.5vw, 2.5vw, 0);}
  //       &--right{ transform: translate3d(17.5vw, 2.5vw, 0);}
  //     }
  //     &__shape{
  //       svg rect, svg circle, svg polygon{
  //         fill-opacity: 0;
  //       }
  //     }
  //     &__title{
  //       opacity: 0;
  //       transform: translateX(2vw);
  //     }
  //   }
  // }
  //
  // &--draw{
  //   .hb{
  //     &__wrap{
  //       opacity: 1;
  //       &--cta-dummy{
  //         svg rect{
  //           stroke-dasharray: 300;
  //           stroke-dashoffset: 300;
  //           animation: rect-stroke $t linear forwards;
  //           animation-delay: 0*$t;
  //         }
  //       }
  //       &--left{
  //         svg rect{
  //           stroke-dasharray: 300;
  //           stroke-dashoffset: 300;
  //           animation: rect-stroke $t linear forwards;
  //           animation-delay: 0.16*$t;
  //         }
  //       }
  //       &--center{
  //         svg{
  //           transform: rotateX(180deg) translateX(-2px);
  //           @include mobile{
  //             transform: rotateX(180deg);
  //           }
  //         }
  //         svg circle{
  //           stroke-dasharray: 314.1;
  //           stroke-dashoffset: 314.1;
  //           animation: circ-stroke $t linear forwards;
  //           animation-delay: 1.06*$t;
  //         }
  //       }
  //       &--right{
  //         svg{
  //           transform: translateX(-2px);
  //           @include mobile{
  //             transform: translateX(0px);
  //           }
  //         }
  //         svg polygon{
  //           stroke-dasharray: 341;
  //           stroke-dashoffset: 341;
  //           animation: tri-stroke $t linear forwards;
  //           animation-delay: 0.64*$t;
  //         }
  //       }
  //     }
  //   }
  // }

}

@keyframes rect-stroke{
  0% {stroke-dashoffset: 300;}
  100% {stroke-dashoffset: 0;}
}

@keyframes circ-stroke{
  0% {stroke-dashoffset: 314.1;}
  100% {stroke-dashoffset: 0;}
}
@keyframes tri-stroke{
  0% {stroke-dashoffset: 341;}
  100% {stroke-dashoffset: 0;}
}
