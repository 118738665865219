
.program {
  @include mobile {
    max-width: 85vw;
    margin: auto;
  }
  &__top {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 35vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/start/Thumbnails-1.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: left;
      }
    }

  }

  &__top2{
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 35vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/start/Thumbnails.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: left;
      }
    }
  }


  &__top3{
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 35vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../assets/images/start/Thumbnails3.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: left;
      }
    }
  }
  &__top-left {
    width: 50%;
    @include mobile {
      width: 100%;
      order: 1;
      .program__top-image {
        display: none;
      }
    }
  }
  &__top-left-content {
    position: relative;
    top: 3rem;
    p {
      width: 70%;
      @extend .text-small;
      @include mobile {
        width: 100%;
      }
    }
  }
  &__top-image {
    height: 35vh;
    width: 100%;
    margin-top: 2.5vh;
    background-position: center center;
    background-repeat: no-repeat;
    //background-image: url(http://via.placeholder.com/100x100);
    background-size: contain;
    @include mobile{
      margin-bottom: 2.5vh;
    }
  }
  &__top-right {
    width: 50%;
    position: relative;
    .program__top-image {
      display: none;
    }
    p {
      @extend .text-small;
    }

    @include mobile {
      width: 100%;
      order: 0;
      .program__top-image {
        display: block;
        height: 30vh;
      }
    }

    &:after {
      content: '';
      width: calc(80% - 2px);
      height: 80%;
      top: 18%;
      left: 0;
      border: 2px solid $yellow;
      position: absolute;
      @include tablet {
        top: 8%;
      }
      @include mobile {
        display: none;
      }
    }

  }
  &__top-right-content1 {
    width: 70%;
    height: 40vh;
    padding-left: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    p {
      @extend .text-small;
    }
    @include tablet {
      p {
        width: 70%;
      }
    }
    @include mobile {
      padding-left: 1rem;
      position:relative;
      top:10vh;
      height:17vh;
      margin-bottom:23vh;
      background:rgba(255,255,255,0.5);
      width:100%;
      h2 {
        padding-top: 1rem;
      }
      p{

        width: 100%;
      }
    }

  }

  &__top-right-content2 {
    padding-left: 1rem;
    p {
      width: 70%;
      @extend .text-small;
    }
    @include mobile {
      padding-left: 0;
      p {
        width: 100%;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;
    margin-top: 5rem;
  }
  &__bottom-left {
    width: 50%;
    @include mobile {
      width: 100%;
    }
    ul {
      color: $white;
      width: 70%;
      @extend .text-small;
      list-style-type: disc;
      @extend .text-lineSpace;
      @include mobile {
        width: 100%;
      }
    }
    li {
      list-style-type: disc;
    }
  }
  &__bottom-right {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }
    &:after {
      content: '';
      width: calc(80% - 2px);
      height: calc(100% - 2px);
      top: 0;
      left: 0;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
    ul {
      color: $white;
      font-size: 16px;
      @extend .text-small;
      @extend .text-lineSpace;
      padding: 2rem 3rem 0rem 3rem;
      @include mobile {
        padding-left: 0;
      }
    }
    li {
      list-style-type: disc;
      margin-bottom: 0.5rem;
    }
  }
  &__link {
    background-color: rgba($white, 0.8);
    position: absolute;
    left: 0;
    padding: 1rem 0.5rem 1rem 4rem;
    width: 80%;
    bottom: 0;
    @include mobile {
      padding-left: 4rem;
      width: 100%;
    }
  }
  &__link-image {
    width: 2rem;
    height: 2rem;
    position: absolute;
    background-image: url(../assets/images/hatchquarter-start-program-link.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 1.3rem;
    top: 1rem;
    margin-right: 0.5rem;
  }

  &__footer-links {
    border-top: 2px solid $yellow;
    margin-top: 2rem;
  }

  &__footer-links-inner {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    margin: auto;
    padding-top: 2rem;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      flex-flow: column;
    }
  }
  &__footer-link {
    color: $white;
    text-decoration: none;
    transition: color 0.5s ease;

    &:hover {
      color: $yellow;
    }
    @include mobile {
      margin-bottom: 1rem;
    }
  }

}
.program-outer {
  @include mobile {
    padding-top: 0;
  }
}

.list-indent {
  margin-left: 1rem;
}
