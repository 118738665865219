/*---- Small classes that can be added to the HTML markup to give simple, consistent styles across the site ---*/
.bg-light-grey { background-color: $light-grey; }
.bg-dark-grey { background-color: $dark-grey; }
.bg-blue { background-color: $blue; }
.bg-black { background-color: $black; }
.bg-yellow { background-color: $yellow; }
.bg-white { background-color: $white; }
.bg-newgrey1 { background-color: $bg-grey; }
.bg-newgrey2 { background-color: $bg-grey2; }
.bg-lightest-grey { background-color: $lightest-grey; }

.hidden { opacity: 0; }
.element-center {	margin: 0 auto; }

.force-full {
	@include tablet {
		max-width: 100vw !important;
		width: 100vw !important;
	}
}
.content-margin {
	height: 100vh;
	width: 100%;
	content: '';
}
.content-margin-medium {
	height: 60vh;
	width: 100%;
	content: '';
}
.space {
	margin: 1rem 0;
	&--double { margin: 2rem auto !important;	}
}
.main-spacer {
	padding: 5rem 0 !important;
}

.space-top {
	margin-top: 1rem;
	&--double { margin-top: 2rem;	}
	&--quad {	margin-top: 4rem;	}
	@include mobile{
		&--mobile-quad{ margin-top: 4rem; }
	}
}

.space-bottom{
	margin-bottom: 1rem;
	&--double{ margin-bottom: 2rem; }
	&--quad{ margin-bottom: 4rem; }
	&--large { margin-bottom: 5rem !important; }
}
.spacer {
	margin: 0 auto !important;
	padding: 7rem 0 !important;
}
.pad{
	padding: 1rem;
	&-top {	padding-top: 1rem; }
	&-bottom {	padding-bottom: 1rem; }
	&--double { padding: 2rem; }
	&-top-half { padding-top: 0.5rem; }
	&-bottom-half { padding-bottom: 0.5rem; }
}
.kill-pad {
	@include mobile {
		padding: 0 !important;
	}
}
.social-icons{
	height: 60px;
	width: 60px;
	background-color: white;
	border-radius: 50%;
	display: inline-block;
	margin: $base;
}
.mobile--wrap {
	@include mobile {
		width: 90%;
		margin: 0 auto;
	}
}
.yellow-bottom { border-bottom: 2px solid $yellow; }

.border { border: 4px solid $dark-grey; }
.height-100 { height: 100%; }
.no-left-border {
	@include desk {
		border-left: none !important;
	}
	@include tablet {
		border-left: 1px solid $white !important;
	}
}


.send-back { z-index: -1 !important; }
.send-front { z-index: 5 !important; }

.circle {
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 15vw;
	justify-content: center;
	margin: auto;
	width: 15vw;
	@include tablet {
		height: 55vw;
		width: 55vw;
	}

}

.hq-cta {
	display: flex;
	flex-flow: row;
	flex: 1;
	justify-content: space-around;
	a {
		position: relative;
		padding-left: 2rem;
		&:before {
			content: '';
			height: 1.5rem;
			width: 1.5rem;
			position: absolute;
			left: 0;
			top: -0.25rem;
			// background-image: url('../assets/images/hatch-quarter-community-link.svg');
			background-size: contain;
			background-repeat: no-repeat;
		}
		@include mobile {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	.cta-community {
		font-size: 1.2rem;
		&:before {
			// background-image: url('../assets/images/hatch-quarter-community-link.svg');
		}
	}
	.cta-programs {
		&:before {
			background-image: url('../assets/images/hatch-quarter-programs-link.svg');
		}
	}
	.cta-mentors {
		&:before {
			background-image: url('../assets/images/hatch-quarter-mentors-link.svg');
		}
		@include mobile {
			margin-bottom: 0;
		}
	}

	@include mobile {
		flex-wrap: wrap;
		padding: 1rem 0;
	}

}
