.modal {
  box-shadow: 0 0 15px 5px rgba($black, 0.5);
}

.modal-body {
  &__para {
    text-align: center;
    line-height: 1.5;
    margin: 0.8rem 1rem 1rem;
  }
}

.modal-title {
  position: relative;
}

a.modal-close-btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  color: $white;
  transition: color 0.5s ease;

  &:hover {
    color: $yellow;
  }
}