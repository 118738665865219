.mentors {
  &--card {
    height: 360px;
		@include span(30%);
		margin-right: 5%;
		font-weight: 200;
		margin-bottom: 5%;
		&:nth-child(3n) {
			margin-right: 0%;
      @include tablet {
        margin-right: 10%;
      }
      @include mobile {
        //margin: auto;
      }
		}
    @include tablet {
      @include span(45%);
      margin-right: 10%;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include mobile {
      @include span(80%);
      margin: auto;
      margin-right: 10%;
      margin-left: 10%;
      margin-bottom: 5%;
      &:nth-child(2n) {
        //margin: auto;
      }
    }
		&-image {
			height: 50%;
			width: 90%;
      margin: auto;
      margin-top: $base;
      background-color: $light-grey;
			@include bg-image();
			display: block;
		}
		&-content {
			height: 50%;
      width: 80%;
      margin: auto;
			display: block;
		}
    &-heading {
			height: 25%;
			padding: 0.5rem 0;
			border-bottom: 1px solid $white;
			font-size: 1.15rem;
			text-transform: uppercase;
		}
    &-summary {
			height: 40%;
			width: 100%;
			overflow: hidden;
		}
  }
}
