$postTranslate: translateY(-30vh);
.blog {
	&--header-post{
		height:65vh !important;
	}
	@include mobile {
		display: flex;
		flex-direction: column;
	}
	//Index Cards
	&--card {
		position: relative;
		min-height: 420px;
		height: auto;
		@include span(30%);
		margin-right: 5%;
		font-weight: 200;
		margin-bottom: 5%;
		@include tablet {
			@include span(42%);
			min-height: 380px;
		}
		@include mobile {
			@include span(80%);
			display: block;
			height: 470px;
			transform: translateX(12.5%);
			min-height: 300px;
		}
		&:nth-child(3n) {
			margin-right: 0%;
			@include tablet {
				margin-right: 5%;
			}
	 	}
		&:nth-child(2n) {
			@include tablet {
				margin-right: 0%;
			 }
		 }
		transition: all $short $ease-in-out;
	  // //box-shadow: $mild-shadow;
	  // &:hover { //box-shadow: $shadow; }

		&:after {
      content: '';
      width: 45%;
      height: 100%;
      top: 0;
      right:0;
      position: absolute;
      background-color: rgba($white, 0.4);
    }
		&-image {
			height: 170px;
			width: 100%;
			@include bg-image();
			display: block;
		}
		&-content {
			height: 70%;
			width: 100%;
			display: block;
			padding-right: 1rem;
		}
		&-heading {
			min-height: 84px;
			height: auto;
			position: relative;
			display: block;
			//padding: 0.5rem 0;
			//border-bottom: 1px solid $white;
			//font-size: 1.15rem;
			//text-transform: uppercase;
			@extend .text-small;
			@extend .text-thicker;
			padding: 1rem 0;
			@include tablet {
				min-height: 100px;
			}
		}
		&-author, &-date {
			padding: 5px 0;
			width: 50%;
			height: 5%;
			font-size: $small-size;
			font-weight: 100;
			float: left;
			margin-bottom: $base;
		}
		&-date { text-align: right; }
		&-summary {
			height: 40%;
			width: 100%;
			overflow: hidden;
			@extend .text-tiny;
			@extend .text-thick;
			color: $dark-grey;
			//font-weight: 300;
			p {
				line-height: 14px;
			}

		}
		&-button {
			width: 87.5%;
			height: 25px;
			display: flex;
			position: absolute;
			bottom: 0.5rem;
			align-items: center;
		}
		&-link {
			text-decoration: underline;
			@extend .text-niveau-bold;
		}
	}
	//Post Artilce
	&--post-container { height: calc(auto - 30vh); }
	&--article {
		height: auto;
		width: 100%;
		//transform: $postTranslate;
		@include mobile {
			width: 90%;
			margin: 0 auto;
		}
		&-header {
			min-height: 400px;
			height: auto;
			padding: 2.5rem 5rem;
			margin-bottom: 2rem;
			display: flex;
			align-items: center;
			flex-direction: column;

			.row {
				width: 100%;

				&:nth-child(1) {
					align-self: flex-start;
				}
				&:nth-child(2) {
					margin: auto;
				}
				&:nth-child(3) {
					align-self: flex-end;
				}
			}

			@include mobile {
				padding: 2rem;
				height: 300px;
			}
			h1 {
				font-size: 4rem;
				@include tablet {
					font-size: 2rem;
				}
			}
			&__inner {
				width: 65%;
				margin: 0 auto;
				@include mobile {
					width: 100%;
				}
			}

		}
		&-content {
			width: 80%;
			margin: 0 auto;
			padding: 1rem;
			border-bottom: 2px solid $yellow;
			p {
				margin-bottom: $base;
				@extend .text-small;
				line-height: 16px;
				font-weight: 400;
			}
			@include mobile {
				width: 100%;
			}
		}
		&-share {
			width: 30%;
			height: 45px;
			margin: 0 auto;
			margin-top: 2rem;
			@include mobile {
				width: 60%;
			}
			&-item {
				text-align: center;
				@include span(4);
				display: inline-block;
				height: 100%;
				font-size: 3rem;
				color: $yellow;
			}
			&__wrap {
				//transform: translateY(-15vh);
				@include mobile {
					width: 90%;
					margin: 0 auto;
				}
			}
		}

	}
	&--category-outer {
		width: 100%;
	}
	&--categories {
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		// @include mobile {
		// 	&:first-child {
		// 		margin-left: $base*2;
		// 	}
		// }
		&-item {
			display: inline-block;
			margin-right: $base;

		}
	}
	&--header-image {
		height: 100%;
		width: 100%;
		@include bg-image();
	}
	&--index-button {
		width: 15%;
		height: 45px;
		border-bottom: 2px solid $yellow;
		float: left;
		padding-right: $base;
		@include mobile {
			margin-top: 3rem;
			width: 40%;
		}
	}
	&--next-post {
		width: 15%;
		height: 45px;
		border-bottom: 2px solid $yellow;
		float: right;
		padding-right: $base;
		text-align: right;
		@include mobile {
			margin-top: 3rem;
			width: 40%;
		}
	}
	.header__footer-links-inner {
		& > div {
			width: 100%;
		}
	}
	&-paging {
		@include tablet {
			position: absolute;
			height: auto;
			width: 100%;
			bottom: 0;
			left: 0;
		}
		@include mobile {
			left: 10%;
		}
	}
	&__subheading {
		@include mobile {
			border-top: 1px solid $light-grey;
			p {
					padding: 0 1rem;
			}

		}
	}
}
.blog-home {
	.paging.blog-paging {
		display: none;
	}
}
.blog-home-link {
	position: absolute;
	bottom: -1rem;
	left: 0;
	padding-left: 2rem;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		width: 1.5rem;
		height: 2px;
		background-color: #4b4b4b;
	}

}

.blog-header-links
{
	position: relative;
	top: 100px;
	.blog--categories-item a{
		color: $white;
	}
	.blog--categories-item a:hover{
		color: #D3D3D3;
	}
}

.blog-section{
	height:50vh !important;
}
