
.grow-program {
  @include mobile {
    max-width: 85vw;
    margin: auto;
  }
  &__inner {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url(../assets/images/grow/Thumbnails.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: right;
      }
    }
  }

  &__inner2 {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url(../assets/images/grow/Thumbnails-grow.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: right;
      }
    }
  }

  &__inner3 {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
    z-index: 0;

    &:before {
      content: '';
      height: 40vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-image: url(../assets/images/grow/Thumbnails-grow-02.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mobile {
        width: 100vw;
        left: -7.5vw;
        background-size: cover;
        background-position: right;
      }
    }
  }
  &__left {
    width: 50%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    &:after {
      content: '';
      width: 80%;
      height: 90%;
      top: 10%;
      left: 1rem;
      border: 2px solid $yellow;
      position: absolute;
      @include mobile {
        display: none;
      }
    }
  }
  &__left-top {
    height: 40vh;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .grow-program__top-image {
      display: none;
    }
    @include mobile {
      padding-left: 1rem;
      position:relative;
      top:10vh;
      height:17vh;
      margin-bottom:23vh;
      background:rgba(255,255,255,0.5);
      width:100%;
      .grow-program__top-image {
        display: block;
        height: 20vh;
      }
    }

  }
  &__left-bottom {
    padding-left: 2rem;

    @include mobile {
      padding-left: 0;
    }

    p {
      @extend .text-small;
      @extend .text-lineSpace;
      width: 70%;
      @include mobile {
        width: 100%;
      }
    }
     ul {
       color: $white;
       width: 70%;
       font-size: 16px;
       @extend .text-small;
       list-style-type: disc;
       padding-left: 2rem;
       margin-top: 2rem;
       @include mobile {
         width: 100%;
       }
     }
     li {
       list-style-type: disc;
         margin-bottom: 0.5rem;
     }
  }
  &__right-top {
    height: 40vh;
    width: 100%;
    position: relative;
    @include mobile {
      height: auto;
      .grow-program__top-image {
        display: none;
      }
    }

  }
  &__right {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }
    &__right-bottom {
      p {
        width: 80%;
        @extend .text-small;
        @extend .text-lineSpace;
        @include mobile {
          width: 100%;
        }
      }

    }
  &__top-image {
    height: 35vh;
    width: 100%;
    margin-top: 2.5vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__link {
    background-color: rgba($white, 0.8);
    position: relative;
    padding: 1rem 0.5rem 1rem 4rem;
    width: 80%;
    @include mobile {
      padding-left: 4rem;
      width: 100%;
    }
  }
  &__link-image {
    width: 2rem;
    height: 2rem;
    position: absolute;
    background-image: url(../assets/images/hatchquarter-start-program-link.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 1.3rem;
    top: 1rem;
    margin-right: 0.5rem;
  }

  &__footer-links {
    border-top: 2px solid $yellow;
    margin-top: 2rem;
  }
  &__footer-links-inner {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-between;
    height: auto;
    margin: auto;
    padding-top: 2rem;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      flex-flow: column;
    }
  }
  &__footer-link {
    color: $white;
    text-decoration: underline;
    @include mobile {
      margin-bottom: 1rem;
    }
  }
}
.grow-program-outer {
  @include mobile {
    padding-top: 0;
  }
}
