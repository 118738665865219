.imageGrid{
  background: $white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &__image{
    @include bg-image;
    display: block;
    width: auto;
    min-width: 33%;
    flex: 1;
    min-height: 20rem;
  }
}
